import { getAppNames, getAppStatus, registerApplication, start } from 'single-spa';

const project = process.env.PROJECT;
const { remotes } = require(`../../${project}/src/mfe/remotes`);

const IsAlreadyRegistered = (remoteName: string): boolean => {
  return getAppNames().includes(remoteName);
}

const StartRemote = (e: CustomEvent) => {
  const { detail: remoteName } = e;
  if (IsAlreadyRegistered(remoteName)) return;

  const remote = remotes.find(remoteModule => remoteModule.name == remoteName)
  if (!remote) {
    console.warn(`Details could not be found for the following remote Module ${remoteName}`);
    return;
  }

  const { name, activeWhen } = remote;
  registerApplication({
    name,
    app: () => System.import(name),
    activeWhen
  });
}

window.addEventListener('NGD_REMOTE', StartRemote);

start({
  urlRerouteOnly: true,
});
